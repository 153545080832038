import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import OurStory1 from "../sections/our-story/our-story-1"
import OurStory2 from "../sections/our-story/our-story-2"
import OurStory3 from "../sections/our-story/our-story-3"
import OurStory4 from "../sections/our-story/our-story-4"
import OurStory5 from "../sections/our-story/our-story-5"

const OurStoryPage = () => {
  return (
    <Layout>
      <Seo
        title="Our Story | Invented by Jane McGonigal"
        description="SuperBetter is a small business working to unlock the heroic potential of youth and young adults around the world."
        keywords="Jane McGonigal"
     />
      <OurStory1 />
      <OurStory2 />
      <OurStory3 />
      <OurStory4 />
      <OurStory5 />
    </Layout>
  )
}

export default OurStoryPage
