import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Col, Image, Row } from "react-bootstrap"
import PlayIcon from "../../images/play-icon.svg"

const OurStory1 = () => {
  return (
    <section id="story-hero" className="position-relative py-5 mt-5">
      <div className="container py-5 mt-5 ">
        <Row>
          <Col md="6" className="jane-img mt-2">
            <StaticImage
              src="../../images/story-1.webp"
              quality={100}
              width={500}
              formats={["AUTO", "WEBP"]}
              placeholder="Our story starts
              with a concussion"
              alt="Our story starts
              with a concussion"
              className="img-fluid about-mira-01"
            />
          </Col>
          <Col
            md="6"
            className="d-flex flex-column justify-content-center align-items-start mt-2"
          >
            <h1>Our story began with a concussion</h1>
            <p>
              Jane McGonigal is a researcher and game designer. After a serious
              concussion, she experienced anxiety, depression and suicidal
              ideation. Jane invented SuperBetter to help herself heal.
            </p>
            <p>
              Since then, over 1 million people have played SuperBetter to
              overcome obstacles in their own lives.
            </p>
            <a
              href="https://www.ted.com/talks/jane_mcgonigal_the_game_that_can_give_you_10_extra_years_of_life?language=en"
              className="ted-talk-btn"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image src={PlayIcon} />{" "}
              <span className="inner-text">
              Watch Jane share her story in her popular TED Talk.
              </span>
            </a>
          </Col>
        </Row>
      </div>
    </section>
  )
}

export default OurStory1
