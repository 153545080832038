import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Col, Row } from "react-bootstrap"

const OurStory4 = () => {
  return (
    <section id="story-mental-health" className="position-relative py-5">
      <div className="container py-3">
        <Row>
          <Col md="12" className="mb-5">
            <h1 style={{ maxWidth: "905px" }}>
              Powered by the science of games
            </h1>
          </Col>
          <Col
            md="6"
            className="d-flex flex-column justify-content-center pt-5 mt-3"
          >
            <h5>There are 3 billion video game players on the planet</h5>
            <ul>
              <li>
                40% of the world adopts a gameful mindset every time they play.
              </li>
              <li>
                The mindset of game play is a powerful framework for all of
                life.
              </li>
              <li>
                We can use the same psychology to tackle our biggest challenges.
              </li>
            </ul>
            <Link to="/the-science" className="learn-about">
              Learn about the Science
            </Link>
          </Col>
          <Col
            md="6"
            className="d-flex justify-content-center align-items-center"
          >
            <StaticImage
              src="../../images/story-4.webp"
              quality={100}
              width={500}
              formats={["AUTO", "WEBP"]}
              placeholder="Powered by the science of games"
              alt="Powered by the science of games"
              className="img-fluid about-mira-01"
            />
          </Col>
        </Row>
      </div>
    </section>
  )
}

export default OurStory4
