import React from "react"
import { Col, Row } from "react-bootstrap"
import { Link } from "gatsby"

const OurStory5 = () => {
  return (
    <section id="story-news" className="position-relative">
      <div className="container">
        <Row>
          <Col md="12" className="mb-5">
            <h1>Who’s talking about SuperBetter?</h1>
            <Link
              to={'/press'}
              className="talking-about pointer"
            >In the News</Link>
          </Col>
        </Row>
      </div>
    </section>
  )
}

export default OurStory5;
