import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Col, Row } from "react-bootstrap"

const OurStory3 = () => {
  return (
    <section id="story-mental-health" className="position-relative py-5">
      <div className="container py-3">
        <Row>
          <Col md="12" >
            <h1>We’re tackling the youth mental health crisis</h1>
          </Col>
          <Col md="6" className="mt-3">
          <StaticImage
              src="../../images/story-3.webp"
              quality={100}
              width={500}
              formats={["AUTO", "WEBP"]}
              placeholder="mental health crisis"
              alt="mental health crisisr"
              className="img-fluid about-mira-01"
            />
          </Col>
          <Col md="6" className="mt-3 d-flex flex-column justify-content-center">
            <h5>SuperBetter is a small business with big dreams</h5>
            <ul>
              <li>Our mission: Unlock the heroic potential of youth & young adults everywhere.</li>
              <li>
              Ending the youth mental health crisis requires both prevention & treatment.
              </li>
              <li>
              SuperBetter fills the gap for prevention that is evidence-based, accessible, affordable and globally-scalable.

              </li>
            </ul>
          </Col>
        </Row>
      </div>
    </section>
  )
}

export default OurStory3;
