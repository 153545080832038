import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Col, Row } from "react-bootstrap"

const OurStory2 = () => {
  return (
    <section id="story-best-seller" className="position-relative py-5">
      <div className="container py-3">
        <Row>
          <Col
            md="8"
            className="d-flex flex-column justify-content-center align-items-start"
          >
            <p>
            The <span className="it-lic">SuperBetter </span>  book by Jane McGonigal is a <span className="it-lic">NY Times</span> bestseller. 
               It explains the science behind each rule for playing SuperBetter.
            </p>
            <a
              href="https://www.amazon.com/SuperBetter-Living-Gamefully-Jane-McGonigal/dp/0143109774"
              className="get-book mx-auto mx-md-0 mb-5 mb-md-0"
              target="_blank"
              rel="noreferrer"
            >
              Get the Book
            </a>
          </Col>
          <Col
            md="4"
            className="d-flex justify-content-center align-items-center"
          >
            <StaticImage
              src="../../images/story-2.png"
              quality={100}
              formats={["AUTO", "WEBP"]}
              placeholder="Best Seller Book Super Better"
              alt="Best Seller Book Super Better"
              className="img-fluid about-mira-01"
            />
          </Col>
        </Row>
      </div>
    </section>
  )
}

export default OurStory2
